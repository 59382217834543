import React, { useState, useEffect } from 'react';
import { IoMdArrowDropright } from "react-icons/io";
import Tabthreecontent from './tabthreecontent';
import erp2 from '../../assets/images/bg/erp2.png';
import crm2 from '../../assets/images/bg/crm2.png';
import powerbi from '../../assets/images/bg/powerbi.png';
import tel3 from '../../assets/images/bg/tel3.png';
import web from '../../assets/images/bg/web.png';
import erpwhite from '../../assets/images/bg/whitesettings.png';
import crmwhite from '../../assets/images/bg/whiteperson.png';
import telwhite from '../../assets/images/bg/whitetel.png';
import laptopwhite from '../../assets/images/bg/whitelaptop.png';
import whitesütun from '../../assets/images/bg/whitestn.png';

const TabContent = ({ children }) => (
  <div className='newclas'>
    {children}
  </div>
);

const TabPanel3 = ({translations}) => {
  const [activeTab, setActiveTab] = useState('about');
  const [isMobile, setIsMobile] = useState(window.innerWidth < 500);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleSelectChange = (event) => {
    setActiveTab(event.target.value);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 500);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const servicesData = {
    about: {
      title:`${translations.servicedata1title}`,
      description:`${translations.servicedata1desc}`,
      buttonText: `${translations.servicebutton}`,
      services: [
        `${translations.servicedata1sub1}`,
        `${translations.servicedata1sub2}`,
        `${translations.servicedata1sub3}`,
        `${translations.servicedata1sub4}`,
        `${translations.servicedata1sub5}`
      ],
      imgSrc: erp2,
      secondimage: erpwhite
    },
    recognition: {
      title: `${translations.servicedata2title}`,
      description: `${translations.servicedata2desc}`,
      buttonText: `${translations.servicebutton}`,
      services: [
        `${translations.servicedata2sub1}`,
        `${translations.servicedata2sub2}`,
        `${translations.servicedata2sub3}`,
        `${translations.servicedata2sub4}`,
        `${translations.servicedata2sub5}`
      ],
      imgSrc: crm2,
      secondimage: crmwhite
    },
    clients: {
      title: `${translations.servicedata3title}`,
      description:`${translations.servicedata3desc}`,
      buttonText: `${translations.servicebutton}`,
      services: [
        `${translations.servicedata3sub1}`,
        `${translations.servicedata3sub2}`,
        `${translations.servicedata3sub3}`,
        `${translations.servicedata3sub4}`,
        `${translations.servicedata3sub5}`
      ],
      imgSrc: web,
      secondimage: laptopwhite
    },
    awards: {
      title: `${translations.servicedata4title}`,
      description: `${translations.servicedata4desc}`,
      buttonText: `${translations.servicebutton}`,
      services: [
        `${translations.servicedata4sub1}`,
        `${translations.servicedata4sub2}`,
        `${translations.servicedata4sub3}`,
        `${translations.servicedata4sub4}`,
        `${translations.servicedata4sub5}`
      ],
      imgSrc: powerbi,
      secondimage: whitesütun
    },
    mobile: {
      title: `${translations.servicedata5title}`,
      description: `${translations.servicedata5desc}`,
      buttonText: `${translations.servicebutton}`,
      services: [
        `${translations.servicedata5sub1}`,
        `${translations.servicedata5sub2}`,
        `${translations.servicedata5sub3}`,
        `${translations.servicedata5sub4}`,
        `${translations.servicedata5sub5}`
      ],
      imgSrc: tel3,
      secondimage: telwhite
    }
  };

  return (
    <div className="tabs-containerbuz2">
      <div className="tabs-headerbuz2">
        {isMobile ? (
          <select value={activeTab} onChange={handleSelectChange} className="tab-selectbuz2">
            <option value="about">ERP</option>
            <option value="recognition">CRM</option>
            <option value="awards">POWER BI</option>
            <option value="mobile">MOBİL</option>
            <option value="clients">WEB</option>
          </select>
        ) : (
          <>
            <button className={`tab-buttonbuz2 ${activeTab === 'about' ? 'active' : ''}`} onClick={() => handleTabClick('about')}>
              <span className='roboto-bold styleheadertab2'>ERP</span>
            </button>
            <button className={`tab-buttonbuz2 ${activeTab === 'recognition' ? 'active' : ''}`} onClick={() => handleTabClick('recognition')}>
              <span className='roboto-black styleheadertab2'>CRM</span>
            </button>
            <button className={`tab-buttonbuz2 ${activeTab === 'awards' ? 'active' : ''}`} onClick={() => handleTabClick('awards')}>
              <span className='roboto-bold styleheadertab2'>POWER BI</span>
            </button>
            <button className={`tab-buttonbuz2 ${activeTab === 'mobile' ? 'active' : ''}`} onClick={() => handleTabClick('mobile')}>
              <span className='roboto-bold styleheadertab2'>MOBİL</span>
            </button>
            <button className={`tab-buttonbuz2 ${activeTab === 'clients' ? 'active' : ''}`} onClick={() => handleTabClick('clients')}>
              <span className='roboto-bold styleheadertab2'>WEB</span>
            </button>
          </>
        )}
      </div>
      <div className="tabs-contentbuz">
        {activeTab === 'about' && (
          <TabContent>
            <Tabthreecontent
              title={servicesData.about.title}
              description={servicesData.about.description}
              buttonText={servicesData.about.buttonText}
              services={servicesData.about.services}
              imgSrc={servicesData.about.imgSrc}
              secondimage={servicesData.about.secondimage}
            />
          </TabContent>
        )}
        {activeTab === 'recognition' && (
          <TabContent>
            <Tabthreecontent
              title={servicesData.recognition.title}
              description={servicesData.recognition.description}
              buttonText={servicesData.recognition.buttonText}
              services={servicesData.recognition.services}
              imgSrc={servicesData.recognition.imgSrc}
              secondimage={servicesData.recognition.secondimage}
            />
          </TabContent>
        )}
        {activeTab === 'clients' && (
          <TabContent>
            <Tabthreecontent
              title={servicesData.clients.title}
              description={servicesData.clients.description}
              buttonText={servicesData.clients.buttonText}
              services={servicesData.clients.services}
              imgSrc={servicesData.clients.imgSrc}
              secondimage={servicesData.clients.secondimage}
            />
          </TabContent>
        )}
        {activeTab === 'awards' && (
          <TabContent>
            <Tabthreecontent
              title={servicesData.awards.title}
              description={servicesData.awards.description}
              buttonText={servicesData.awards.buttonText}
              services={servicesData.awards.services}
              imgSrc={servicesData.awards.imgSrc}
              secondimage={servicesData.awards.secondimage}
            />
          </TabContent>
        )}
        {activeTab === 'mobile' && (
          <TabContent>
            <Tabthreecontent
              title={servicesData.mobile.title}
              description={servicesData.mobile.description}
              buttonText={servicesData.mobile.buttonText}
              services={servicesData.mobile.services}
              imgSrc={servicesData.mobile.imgSrc}
              secondimage={servicesData.mobile.secondimage}
            />
          </TabContent>
        )}
      </div>
    </div>
  );
};

export default TabPanel3;
