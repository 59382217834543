import React, { useEffect,useContext } from 'react';
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderOne';
import HeaderTopBar from '../common/header/HeaderTopBar';
import FooterThree from '../common/footer/FooterThree';
import BrandThree from '../elements/brand/BrandThree';
import AboutFour from '../elements/about/AboutFour';
import ServiceOne from "../elements/service/ServiceOne";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import CounterUpFour from "../elements/counterup/CounterUpFour";
import TeamFour from "../elements/team/TeamFour";
import TimelineTwo from "../elements/timeline/TimelineTwo";
import Separator from "../elements/separator/Separator";
import AboutThree from '../elements/about/AboutThree';
import ServiceFive from "../elements/service/ServiceFive";
import Elements from 'elements/gallery/Gallery';
import {Link} from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import FooterFour from 'common/footer/FooterFour';
import LanguageContext from '../LanguageContext';

import ContactFormHome from 'elements/worldmaps/customformhome';
function AboutUsHome  ()  {
  const { translations } = useContext(LanguageContext);

    return (
        <>
            <SEO title="Hakkımızda" />
            <main className="modal-contentxhome" >
               <div>
            <HeaderOne  btnStyle="btn-small btn-icon" HeaderSTyle="rn-header  header-transparent header-default " Loco="main"/>
            </div>
<div className="containerxds">
  <div  className="itemxds leftpad">
    <div className='itemxdsinner'>
      <span style={{marginBottom:-25}} className="roboto-bold textonewhite">{translations.contantlefin}</span>
    </div>
    <div className='itemxdsinner'>
      <span className="roboto-bold textonealt">{translations.contantlefintitle}</span>
      <div className='itemxdsinnerflex'>
      <div style={{display:"flex",flexDirection:"column" ,lineHeight:"1.3rem"}}>
        <span className='roboto-regular'>{translations.contantlefinsub1top}</span>
        <span className='roboto-bold'>{translations.contantlefinsub1bottom}</span>
      </div>
      <div className='classclass'></div>
      <div style={{display:"flex",flexDirection:"column",lineHeight:"1.3rem"}}>
        <span className='roboto-regular'>{translations.contantlefinsub2top}</span>
        <span className='roboto-bold'>{translations.contantlefinsub2bottom}</span>
      </div>
      <div className='classclass'></div>
      <div style={{display:"flex",flexDirection:"column",lineHeight:"1.3rem"}}>
        <span className='roboto-regular'>{translations.contantlefinsub3top}</span>
        <span className='roboto-bold'>{translations.contantlefinsub3bottom}</span>
      </div>
    </div>
    </div>


  </div>
  <div class="itemxds">
  <div  className='itemxdsinnerxds'>
  <div className='itemxdsinnertrans'>
  <span className="roboto-bold textonebluetwo"  style={{color:"white"}}>{translations.contantleformtitle}</span>
  <div style={{width:"100%"}}>
  <ContactFormHome translations={translations} />

  </div>

  </div>

    </div>

  </div>
  <div style={{opacity:0.2}}>
              <h1 className="responsive-heading2 beyond">{translations.contantleformjoin}</h1>
            </div>
</div>
<div style={{position:"relative",zIndex:5}}>
  <FooterFour translations={translations}/>

</div>
            </main>
        </>
    )
}

export default AboutUsHome;
