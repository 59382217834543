import BlogClassicData from '../data/blog/BlogListt.json';
import BlogDetailsContent from '../components/blog/BlogDetailsContent';
import SEO from "../common/SEO";
import { useParams } from 'react-router-dom'; // useParams eklemeyi unutmayın
import HeaderTopBar from '../common/header/HeaderTopBar';
import HeaderOne from '../common/header/HeaderOne';
import FooterFour from '../common/footer/FooterFour';
import ContactInfoCustom from '../elements/worldmaps/contactinfo';
import LanguageContext from 'LanguageContext';
import React, { useState, useEffect, useContext } from 'react';

function BlogDetails ()  {

    
    const { translations } = useContext(LanguageContext);

    const { id } = useParams(); // useParams ile id'yi alın
    const blogId = parseInt(id, 10);
    const data = BlogClassicData.blog.filter(blog => blog.id === blogId);

    return (
        <div className='gradient-background'>
            <SEO title="BlogDetay" />
            <div>
            <HeaderOne btnStyle="btn-small btn-icon" HeaderSTyle="header-not-transparent" isBorder={true} />
          </div>
          
                <div className="rn-blog-details-area">
                    <BlogDetailsContent id={id} />
                </div>

                <FooterFour translations={translations} />
            <ContactInfoCustom translations={translations} />
        </div>
    );
};

export default BlogDetails;
