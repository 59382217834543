import React, { useState, useEffect, useContext } from 'react';
import { FaSistrix } from "react-icons/fa";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../../elements/breadcrumb/BreadcrumbOne";
import BlogPropAstra from './itemProp/BlogPropAstral';
import SideCategories from './sidebar/SideCategories';
import SidebarPost from './sidebar/SidebarPost';
import SidebarTag from './sidebar/SidebarTag';
import SidebarArchive from './sidebar/SidebarArchive';
import LanguageContext from '../../LanguageContext';
import HeaderOne from 'common/header/HeaderOne';
import FooterFour from 'common/footer/FooterFour';
import ContactInfoCustom from 'elements/worldmaps/contactinfo';
import { IoMdArrowDropright } from "react-icons/io";
import Littlenews from 'elements/worldmaps/littlenews';
import SearchBar from 'elements/worldmaps/newsearch';
import { Link } from "react-router-dom";

const BlogGridSidebar = () => {
    const [data, setData] = useState([]);
    const [lastElement, setLastElement] = useState(null);
    const [box2Data, setBox2Data] = useState([]);
    const [blogPropData, setBlogPropData] = useState([]);
    const [currentData, setCurrentData] = useState([]);
    const { translations } = useContext(LanguageContext);

    const fetchData = async () => {
        try {
          const response = await fetch("https://mrtportservices.com:5000/getData?blog=blog&origin=astwrlwde");
          const result = await response.json();
          setData(result.slice());
          setLastElement(result[0]);
          setBox2Data(result.slice(1, 4));
          setBlogPropData(result.slice(4));
          setCurrentData(result.slice(4));
        } catch (error) {
          console.error("Error fetching data:", error);
        }
    };

    const updateDataBasedOnWidth = () => {
        if (window.innerWidth < 1200) {
            setCurrentData(data);
        } else {
            setCurrentData(blogPropData);
        }
    };

    useEffect(() => {
        fetchData();
        updateDataBasedOnWidth();
        window.addEventListener('resize', updateDataBasedOnWidth);
        window.addEventListener('load', updateDataBasedOnWidth);

        return () => {
            window.removeEventListener('resize', updateDataBasedOnWidth);
            window.removeEventListener('load', updateDataBasedOnWidth);
        };
    }, []);

    return (
        <div className='page-wrapper modal-contentxblog'>
            <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-not-transparent" />

            <div className='newsholdersdc'>
                <div className='headsfkdf'>
                    <div>
                        <div className='mediaholderxsl'>
                            <h1 className='headeronebgix roboto-bold'>{translations.blogtitle}</h1>
                            <SearchBar translations={translations} />
                        </div>
                        <div className='newheadtutor'></div>
                        <div className="containerxexamp">
                            {lastElement && (
                                <div className="boxxexamp box1xexamp">
                                    <Link to={process.env.PUBLIC_URL + `/blog-detay/${lastElement.id}`}>
                                        <div className='bigholder'>
                                            <img src={`${process.env.PUBLIC_URL}/${lastElement.image}`} alt="Blog Image" className="imgxdbigdsa " />
                                        </div>
                                    </Link>

                                    <div className='fontbingoxx' style={{ marginTop: 15 }}>
                                        <span className='roboto-black fontbingo' style={{ color: "#04E4FF" }}>{lastElement.date}</span>
                                    </div>
                                    <Link to={process.env.PUBLIC_URL + `/blog-detay/${lastElement.id}`}>
                                        <div className='fontbingobigo'>
                                            <span className='roboto-bold '>{lastElement.title}</span>
                                        </div>
                                    </Link>
                                </div>
                            )}
                            <div className="boxxexamp box2xexamp">
                                {box2Data.map((item, index) => (
                                    <Littlenews
                                        key={index}
                                        id={item.id}
                                        title={item.title}
                                        image={item.image}
                                        date={item.date}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="main-content">
                <div className="rn-blog-area">
                    <div className="container xddddddddd" >
                        <div className="row">
                            <div className='dsadf'>
                                <span className='ldst roboto-bold'>{translations.bloglatest}</span>
                                <div style={{ width: "100%", height: "1px", backgroundColor: "rgba(255,255,255,0.3)" }}></div>
                            </div>
                            <div className="col-lg-12">
                                <div className="row">
                                    <BlogPropAstra column="col-lg-4 col-md-6 col-12" data={currentData} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FooterFour translations={translations} />
            <ContactInfoCustom translations={translations} />
        </div>
    );
};

export default BlogGridSidebar;
