import React, { useState } from 'react';
import Modal from 'elements/worldmaps/Modal';

const MenuButton = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <div className="menu-button" onClick={toggleMenu}>
        <div className={`menu-icon ${isOpen ? 'open' : ''}`}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        {isOpen ? '' :  <span className="menu-text roboto-regular">MENU</span>}
      </div>
      <Modal  isOpen={isOpen} toggleModal={toggleMenu} /> {/* Modal bileşenini kullanın */}
    </div>
  );
};

export default MenuButton;
