import React, { useContext, useState, useEffect, useRef } from "react";
import { useLocation } from 'react-router-dom';
import LanguageContext from "../../LanguageContext.js";
import LanguageDropdown from "./LanguageSelect.js";
import { FiArrowRight } from "react-icons/fi";
import MenuButton from "./Menubutton.js";
import { Link } from "react-router-dom";

const Nav = () => {
  const { language, changeLanguage, translations } = useContext(LanguageContext);
  const [activeLink, setActiveLink] = useState('');
  const location = useLocation();
  const sectionRefs = useRef({});

  const sections = {
    '#mainpage': '#mainpage',
    '#services': '#twotacirsection',
    '#globalcustomernetwork': '#thirdtacirsection',
    '#contact': '#fourtacissection'
  };

  useEffect(() => {
    // Bölümlerin referanslarını önceden al
    Object.keys(sections).forEach(id => {
      sectionRefs.current[id] = document.querySelector(sections[id]);
    });

    const onScroll = () => {
      let currentSection = activeLink;

      Object.keys(sections).forEach(id => {
        const section = sectionRefs.current[id];
        if (section) {
          const sectionTop = section.getBoundingClientRect().top;
          const sectionHeight = section.offsetHeight;
          const withinView = sectionTop <= window.innerHeight / 2 && sectionTop >= -sectionHeight / 2;

          if (withinView) {
            currentSection = id;
          }
        }
      });

      if (currentSection !== activeLink) {
        setActiveLink(currentSection);
      }
    };

    const throttledScroll = throttle(onScroll, 100); // Scroll olayını sınırla
    window.addEventListener('scroll', throttledScroll);

    return () => window.removeEventListener('scroll', throttledScroll);
  }, [sections, activeLink]);

  const handleClick = () => {
    window.location.href = '/getoffer';
  };

  const handleLanguageChange = (event) => {
    changeLanguage(event.target.value);
  };
  return (
<div  style={{justifyContent:"flex-end",display:"flex",alignItems:"center"}}>
        <div className="phand" >
          <LanguageDropdown
            language={language}
            changeLanguage={changeLanguage}
          />
        </div>
        <Link to="/contact" >
        <div className="newbutton phand">
          <p className="roboto-regular" style={{margin:0}}>{translations.navcontnavbig}</p>
        </div>
        </Link>

        <div>
        <MenuButton></MenuButton>
        </div>

</div>
  );
};

function throttle(fn, wait) {
  let lastTime = 0;
  return function (...args) {
    const now = new Date().getTime();
    if (now - lastTime < wait) return;
    lastTime = now;
    return fn(...args);
  };
}
export default Nav;

