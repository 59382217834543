import React, { useRef, useEffect } from 'react';

const CustomCarouselHorizontal = ({ slides, speed = 50 }) => {
  const innerRef22 = useRef(null);

  useEffect(() => {
    const interval = setInterval(() => {
      const current = innerRef22.current;
      if (current) {
        const { scrollWidth, clientWidth } = current;
        const currentScroll = current.scrollLeft;
  
        console.log({scrollWidth, clientWidth, currentScroll}); // Değerleri kontrol etmek için
  
        if (currentScroll >= scrollWidth - clientWidth - 1) { // -1 ekleyerek tam sınırda olmasını engelleyin
          console.log('Resetting to start');
          current.scrollLeft = 0; // Direkt olarak başa dön
        } else {
          console.log('Moving right to left');
          current.scrollLeft += 1; // Hareketi daha belirgin yap
        }
      }
    }, speed);
  
    return () => clearInterval(interval);
  }, [speed]);
  return (
    <div ref={innerRef22} className="carousel-container">
      <div  className="carousel-track">
        {slides.map((img, index) => (
          <img src={img.url} alt={`Image ${index + 1}`} className="carousel-image" key={index} />
        ))}
        {slides.map((img, index) => ( // İkinci kez aynı slide'ları ekliyoruz sonsuz döngü için
          <img src={img.url} alt={`Image ${index + 1}`} className="carousel-image" key={index + slides.length} />
        ))}
      </div>
    </div>
  );
};

export default CustomCarouselHorizontal;
