import React, { useContext } from 'react';
import Select from 'react-select';
import LanguageContext from '../../LanguageContext'; // Context'inize göre düzenleyin
import imageworld from '../../assets/images/bg/world.png';

const LanguageDropdown = () => {
  const { language, changeLanguage } = useContext(LanguageContext);

  const options = [
    { value: 'en', label: 'EN', flag: '/images/bg/eng.png' },
    { value: 'tr', label: 'TR', flag: '/images/bg/trk.png' },
    { value: 'ru', label: 'RU', flag: '/images/bg/rus.png' },
    { value: 'de', label: 'DE', flag: '/images/bg/rus.png' },
  ];

  const formatOptionLabel = ({ label, flag }, { context }) => {
    return context === 'menu' ? (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {label}
      </div>
    ) : (
      <div style={{ display: 'flex', alignItems: 'center', height: 40, width: 40 }}>
        <img src={imageworld} alt="Example" />
      </div>
    );
  };

  const DropdownComponents = {
    DropdownIndicator: null, // Dropdown okunu kaldır
    ClearIndicator: null, // Temizleme ikonunu kaldır
    IndicatorsContainer: () => null, // Açılır menü indicator'unu kaldır
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: 'transparent', // Kontrol bölümünün arka planını şeffaf yapar
      border: 'none', // Kenarlığı kaldırır
      boxShadow: 'none', // Gölge kaldırılır
      width: "70px",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: 'rgba(255,255,255,0)', // Menü bölümünün arka plan rengi
      borderColor: '#333',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.15)' // Menü için gölge efekti
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? 'white' : 'transparent', // Seçeneklerin arka plan rengi
      color: 'black',
      '&:hover': {
        backgroundColor: 'white', // Üzerine gelindiğinde seçeneklerin arka plan rengi
        color: 'black'
      }
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#acacac', // Seçili değerin rengi
    })
  };

  const handleChange = (selectedOption) => {
    changeLanguage(selectedOption.value);
  };

  return (
    <Select
      value={options.find(option => option.value === language)}
      onChange={handleChange}
      options={options}
      formatOptionLabel={formatOptionLabel}
      styles={customStyles} // Burada özel stilleri uyguluyoruz
      components={DropdownComponents} // Özelleştirilmiş component'ları burada kullan
    />
  );
};

export default LanguageDropdown;
