import React, { useState, useEffect } from 'react';
import { IoMdArrowDropright } from "react-icons/io";
import { useSpring, animated } from 'react-spring';

function Tabthreecontent({ title, description, buttonText, services, imgSrc, secondimage }) {
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 1000);

  const props = useSpring({
    to: { opacity: 1, transform: 'translateY(0%)' },
    from: { opacity: 0, transform: 'translateY(70%)' },
    config: { tension: 170, friction: 50 }
  });

  const propshorr = useSpring({
    to: { opacity: 1, transform: 'translateX(0%)' },
    from: { opacity: 0, transform: 'translateX(70%)' },
    config: { tension: 170, friction: 50 }
  });

  const sideProps = useSpring({
    to: { opacity: 1 },
    from: { opacity: 1 },
    config: { tension: 170, friction: 26 }
  });

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 1000);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (isLargeScreen) {
    return (
      <div style={{ padding: 0, width: "100vw" }}>
        <div className="row" style={{ margin: 0, padding: 0 }}>
          <animated.div style={sideProps} className="col-lg-4 col-md-12 paddingzero">
            <div className="c-imagexs">
              <div className="img-containerxdddx">
                <img src={imgSrc} alt="Service" className='imgcont' />
              </div>
            </div>
          </animated.div>
          <div className='imgdivtutucu'>
            <img src={secondimage} alt="Service" className='imgcont32' />
          </div>
          <div className="col-lg-8 col-md-12 divds">
            <div style={{ display: "grid", justifyContent: "center", alignItems: "center" }}>
              <div className="c-block__col c-block__content align-center vex">
                <div className="c-block__inner">
                  <div className="c-heading -h2">
                    <h3 className="roboto-bold h3fontsize textsplit">{title}</h3>
                  </div>
                  <animated.div style={props} className="classclassad">
                    <div className="c-block__text is-wysiwyg">
                      <div className="text-big">
                        <p className='roboto-bold'>{description}</p>
                      </div>
                    </div>
                    <a href="/contact" className="c-btn -blue-grad">
                      <span className='grapholderflex'>
                        {buttonText} <IoMdArrowDropright className='newwor' />
                      </span>
                    </a>
                    <div className='grapholder'>
                      {services.map((service, index) => (
                        <div className='DREW' key={index}>
                          <div>
                            <IoMdArrowDropright color='#87c3fa' size={20} />
                          </div>
                          <div>
                            <p className='roboto-bold' style={{  color:"#005073"}}>{service}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </animated.div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div style={{ padding: 0, width: "100vw" }}>
      <div className="row" style={{ margin: 0, padding: 0 }}>
        <animated.div style={sideProps} className="col-lg-4 col-md-12 paddingzero">
          <div className="c-imagexs">
            <div className="img-containerxdddx">
              <img src={imgSrc} alt="Service" className='imgcont' />
            </div>
          </div>
        </animated.div>
        <div className='imgdivtutucu'>
          <img src={secondimage} alt="Service" className='imgcont32' />
        </div>
        <div className="col-lg-8 col-md-12 divds">
          <div style={{ display: "grid", justifyContent: "center", alignItems: "center" }}>
            <div className="c-block__col c-block__content align-center vex">
              <div className="c-block__inner">
                <div className="c-heading -h2">
                  <h3 className="roboto-bold h3fontsize textsplit">{title}</h3>
                </div>
                <animated.div style={props} className="classclassad">
                  <div className="c-block__text is-wysiwyg">
                    <div className="text-big">
                      <p className='roboto-bold'>{description}</p>
                    </div>
                  </div>
                  <div className='grapholder'>
                    {services.map((service, index) => (
                      <div className='DREW' key={index}>
                        <div>
                          <IoMdArrowDropright color='#87c3fa' size={20} />
                        </div>
                        <div>
                          <p className='roboto-bold ' style={{  color:"#005073"}}>{service}</p>
                        </div>
                      </div>
                    ))}
                    <a href="/contact" className="c-btn -blue-grad mg10">
                      <span className='grapholderflex'>
                        {buttonText} <IoMdArrowDropright className='newwor' />z
                      </span>
                    </a>
                  </div>
                </animated.div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Tabthreecontent;
