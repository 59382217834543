import React, { useState, createContext, useEffect } from 'react';

// Dil dosyaları
import en from './locales/en.json';
import tr from './locales/tr.json';
import ru from './locales/ru.json';
import de from './locales/de.json';
import zh from './locales/zh.json';

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  // Tarayıcı dilini kontrol ederek başlangıç dilini ayarlayın
  const getDefaultLanguage = () => {
    const browserLang = navigator.language || navigator.userLanguage;
    if (browserLang.includes('tr')) {
      return 'tr';
    } else if (browserLang.includes('ru')) {
      return 'ru';
    } else if (browserLang.includes('de')) {
      return 'de';
    } else if (browserLang.includes('zh')) {
      return 'zh';
    } else {
      return 'en';
    }
  };

  const [language, setLanguage] = useState(getDefaultLanguage());
  const [translations, setTranslations] = useState(language === 'tr' ? tr : language === 'ru' ? ru : language === 'de' ? de : language === 'zh' ? zh : en);

  useEffect(() => {
    changeLanguage(language); // İlk yüklemede dil ayarlarını güncelleyin
  }, [language]);

  const changeLanguage = (lang) => {
    setLanguage(lang);
    switch (lang) {
      case 'en':
        setTranslations(en);
        break;
      case 'tr':
        setTranslations(tr);
        break;
      case 'ru':
        setTranslations(ru);
        break;
      case 'de':
        setTranslations(de);
        break;
      case 'zh':
        setTranslations(zh);
        break;
      default:
        setTranslations(en);
    }
  };

  return (
    <LanguageContext.Provider value={{ language, translations, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export default LanguageContext;
