import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PageScrollTop from './components/pageToTop/PageScrollTop'


import Finance from "./pages/Finance";


import AboutUs from "./pages/AboutUs";

// Elements import Here 

import Contact from "./elements/contact/Contact";

import HeaderUs from 'pages/HearUs';
import BlogDetails from "./pages/BlogDetails";
import BlogGridSidebar from "./components/blog/BlogGridSidebar";

import Firstsection  from './pages/panel';
import Depo  from './pages/adminpage';
import Services from 'pages/Services';

import { LanguageProvider } from './LanguageContext';
import { MaterialUIControllerProvider } from "./context";
import { AuthContextProvider } from "./context/AuthContext"
import { BrowserRouter } from "react-router-dom";
import Casestudies from 'pages/CaseStudies';
import NewAboutUs from 'pages/newpageaboutus';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';


import Error from "./pages/Error";


// Import Css Here 
import './assets/scss/style.scss';
import { Hearing } from '@mui/icons-material';

function myCustomMethod() {
  return (
    <BrowserRouter>
      <MaterialUIControllerProvider>
        <AuthContextProvider>
          <Firstsection />
        </AuthContextProvider>
      </MaterialUIControllerProvider>
    </BrowserRouter>
  );
}


  
  const App = () => {
    

    const [isAdminPanel, setIsAdminPanel] = useState(false);

    useEffect(() => {
      if (window.location.pathname.includes('/admin') || window.location.pathname.includes('/login')) {
        setIsAdminPanel(true);
     

      } else {
   
        setIsAdminPanel(false);
      }
    }, []);

    return (
      <LanguageProvider>

      <div>
      {isAdminPanel ? (
       myCustomMethod()
      ) : (
          <Router>
          <PageScrollTop>
            <Routes>
              <Route path="/" element={<Finance/>}/> 
              {/* Blog Part  */}
              <Route path="/depopanel" element={<Depo/>}/> 
              <Route path="/news" element={<BlogGridSidebar/>}/>
              <Route path="/blog-detay/:id" element={<BlogDetails/>}/>

              <Route path="/contact" element={<AboutUs/>}/> 
              <Route path="/aboutus" element={<NewAboutUs/>}/> 
  




        
              {/* Hata yönlendirmesi - Yukarıdaki yolların dışındaki herhangi bir yol için */}
              <Route path="/*" element={<Error />} />
            </Routes>
          </PageScrollTop>
        </Router>
        )}
      </div>
      </LanguageProvider>

    )
}


export default App
