import React, { useState, useEffect,useContext } from 'react';
import { RiCompassDiscoverLine } from "react-icons/ri";
import backgroundImage from '../../assets/images/bg/blueback.png'; // Yolu doğru belirttiğinizden emin olun
import CustomCarousel from './emblavertical';
import CustomCarouselHorizontal from './emblahorizantal';
import { MdArrowRight } from "react-icons/md";
import { Link } from 'react-router-dom';

const BackgroundVideo = ({translations}) => {
 
   // Resim verilerini bir array olarak tanımlayın
   const slides = [
    { id: 1, url: 'https://www.progressint.com.tr/images/bg/site1.png', alt: 'Description of Image 1' },
    { id: 2, url: 'https://www.progressint.com.tr/images/bg/buzmavionlineshipper.png', alt: 'Description of Image 2' },
    { id: 3, url: 'https://www.progressint.com.tr/images/bg/site2.png', alt: 'Description of Image 2' },
    { id: 4, url: 'https://www.progressint.com.tr/images/bg/manuport.png', alt: 'Description of Image 2' },
    { id: 5, url: 'https://www.progressint.com.tr/images/bg/site3.png', alt: 'Description of Image 3' },   
    { id: 6, url: 'https://www.progressint.com.tr/images/bg/site7.jpeg', alt: 'Description of Image 4' },
    { id: 7, url: 'https://www.progressint.com.tr/images/bg/site5.png', alt: 'Description of Image 1' },
    { id: 8, url: 'https://www.progressint.com.tr/images/bg/customer2.png', alt: 'Description of Image 1' },
    { id: 9, url: 'https://www.progressint.com.tr/images/bg/site6.png', alt: 'Description of Image 1' },
    { id: 10, url: 'https://www.progressint.com.tr/images/bg/logistica.png', alt: 'Description of Image 1' },
    { id: 11, url: 'https://www.progressint.com.tr/images/bg/site4.png', alt: 'Description of Image 4' },
    { id: 12, url: 'https://www.progressint.com.tr/images/bg/dashboard1.png', alt: 'Description of Image 1' },
    { id: 13, url: 'https://www.progressint.com.tr/images/bg/dashboard2.png', alt: 'Description of Image 1' },
    { id: 14, url: 'https://www.progressint.com.tr/images/bg/dashboard3.png', alt: 'Description of Image 4' },
    { id: 15, url: 'https://www.progressint.com.tr/images/bg/site9.jpeg', alt: 'Description of Image 4' },
    { id: 16, url: 'https://www.progressint.com.tr/images/bg/site10.png', alt: 'Description of Image 6' },

  ];

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}
  const slides1 = shuffleArray([...slides]);
  const slides22 = shuffleArray([...slides]);
  const slides33 = shuffleArray([...slides]);
  const slides2 = [
    { id: 1, url: 'https://www.progressint.com.tr/images/bg/picture1.png', alt: 'Description of Image 1' },
    { id: 2, url: 'https://www.progressint.com.tr/images/bg/picture2.png', alt: 'Description of Image 2' },
    { id: 3, url: 'https://www.progressint.com.tr/images/bg/picture3.png', alt: 'Description of Image 3' },
    { id: 4, url: 'https://www.progressint.com.tr/images/bg/picture4.png', alt: 'Description of Image 4' },
    { id: 5, url: 'https://www.progressint.com.tr/images/bg/picture5.png', alt: 'Description of Image 5' },
    { id: 6, url: 'https://www.progressint.com.tr/images/bg/picture6.png', alt: 'Description of Image 6' },
    { id: 7, url: 'https://www.progressint.com.tr/images/bg/picture7.png', alt: 'Description of Image 7' },
    { id: 8, url: 'https://www.progressint.com.tr/images/bg/picture8.png', alt: 'Description of Image 8' },
    { id: 9, url: 'https://www.progressint.com.tr/images/bg/picture9.png', alt: 'Description of Image 9' },
    { id: 10, url: 'https://www.progressint.com.tr/images/bg/picture10.png', alt: 'Description of Image 10' },
    { id: 11, url: 'https://www.progressint.com.tr/images/bg/picture11.png', alt: 'Description of Image 11' },
    { id: 12, url: 'https://www.progressint.com.tr/images/bg/picture12.png', alt: 'Description of Image 12' },
    { id: 13, url: 'https://www.progressint.com.tr/images/bg/picture13.png', alt: 'Description of Image 13' },
    { id: 14, url: 'https://www.progressint.com.tr/images/bg/picture14.png', alt: 'Description of Image 14' },
    { id: 15, url: 'https://www.progressint.com.tr/images/bg/picture16.png', alt: 'Description of Image 15' },
    { id: 16, url: 'https://www.progressint.com.tr/images/bg/picture141.png', alt: 'Description of Image 15' },
    { id: 17, url: 'https://www.progressint.com.tr/images/bg/picture151.png', alt: 'Description of Image 16' },
    { id: 18, url: 'https://www.progressint.com.tr/images/bg/picture17.png', alt: 'Description of Image 16' },
    { id: 19, url: 'https://www.progressint.com.tr/images/bg/picture18.png', alt: 'Description of Image 17' },
    { id: 20, url: 'https://www.progressint.com.tr/images/bg/picture14.png', alt: 'Description of Image 18' },
    { id: 21, url: 'https://www.progressint.com.tr/images/bg/picture19.png', alt: 'Description of Image 19' },
    { id: 22, url: 'https://www.progressint.com.tr/images/bg/picture20.png', alt: 'Description of Image 19' },
    { id: 23, url: 'https://www.progressint.com.tr/images/bg/picture21.png', alt: 'Description of Image 19' },



    
    

    
  ];


  
  return (
    <div style={{position:"relative",height:`100vh`,width:"100vw",backgroundColor:"black"}} className='page-wrapper'>
    <div className='medakjadrdfsa'>
     <CustomCarousel slides={slides1} speed={35} direction={false}  />
      <CustomCarousel slides={slides22} speed={30} direction={true} />
      <CustomCarousel slides={slides33} speed={35} direction={false}  />
</div>
      <div className="gradient-overlay ">
      <div className='overleyinner'>
        <h1 className='roboto-bold littleheadfont'>{translations.hometopcontent1}</h1>
        <h1 className='roboto-black littlehead2font'>{translations.hometopcontent2}</h1>
        <span className='roboto-regular littlehead3font'>{translations.hometopcontent3}</span>
        <div className='roboto-bold gradient-buttonxx'>
        <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
        <Link to="/contact" style={{ display: "flex", alignItems: "center", textDecoration: 'none', color: 'inherit' }}>

        <span className='gradient-text'>{translations.hometopcontent4}</span>
        <MdArrowRight className='gradient-icon' />
        </Link>

        </div>
</div>

</div>
      </div> 
 

<div style={{ height: "10vh", width: "100vw", backgroundColor: "#03042a", zIndex: 25, position: "relative" }}>
<CustomCarouselHorizontal slides={slides2} speed={30}   />

</div>
    </div>
  );
};

export default BackgroundVideo;