import React, { useEffect, useState,useRef,useContext } from 'react';
import { Link } from 'react-router-dom';
import SEO from '../common/SEO';
import HeaderTwo from '../common/header/HeaderTwo';
import HeaderTopBar from '../common/header/HeaderTopBar';
import HeaderOne from '../common/header/HeaderOne';



import BackgroundVideo from '../elements/worldmaps/pagetacir.js';
import BackgroundVideo2 from '../elements/worldmaps/pagetacir2.js';


import 'react-tooltip/dist/react-tooltip.css';



import LanguageContext from "../LanguageContext";


import FooterTwo from '../common/footer/FooterTwo';
import AboutUsHome from './AboutUsHome';
import ContactInfoCustom from 'elements/worldmaps/contactinfo';

function Finance() {


  const { translations } = useContext(LanguageContext);


  return (
    <>
      <SEO title="Anasayfa" />
      <main className="page-wrapper">
        <div>

          <HeaderOne btnStyle="btn-small btn-icon" HeaderSTyle="rn-header header-default header-transparent header-default " Loco="main" isBorder={true}/>
        </div>
        <div>
        <div id="mainpage"><BackgroundVideo translations={translations}/>
        
        </div>
        <div id="secondpage"><BackgroundVideo2 translations={translations}/>

        </div>
        
        <AboutUsHome translations={translations}/>

        <ContactInfoCustom translations={translations}/>


          </div>
      </main>
    </>
  );
}

export default Finance;

