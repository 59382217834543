import React from 'react';
import {Link} from "react-router-dom";
import CalltoActionSeven from "../../elements/calltoaction/CalltoActionSeven";
import footerOne from "../../data/footer/footerOne.json";
import ScrollTop from "./ScrollTop";
import NewsletterOne from "./NewsletterOne";
import { FiFacebook, FiTwitter, FiInstagram, FiLinkedin, FiPhone  } from "react-icons/fi";
import { FaLinkedinIn } from "react-icons/fa";
import { ImInstagram } from "react-icons/im";

import Logo from "../../elements/logo/Logo";
import { HiPhone } from "react-icons/hi2";
import { FaFacebookSquare } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";
import { IoMailSharp } from "react-icons/io5";

const footerIntem =  footerOne[0];
const footerIntemOne =  footerOne[1];
const footerIntemTwo =  footerOne[2];
const footerIntemThree =  footerOne[3];
const footerIntemFour =  footerOne[4];
const footerIntemFive =  footerOne[5];

const indexOneLink = (footerIntemOne.quicklink);
const indexTwoLink = (footerIntemTwo.quicklink);
const indexThreeLink = (footerIntemThree.quicklink);

const FooterFour = () => {
    return (
        <>
            <footer className="rn-footer footer-style-default no-border">
                <div className="footer-top" style={{position:"relative"}}>
                 <div className='fotcss'>
                 <div style={{width:"100%", display:"flex",flexDirection:"row"}}>
                 <h1
                 className="beyond" style={{fontSize:30}}>
                 PROGRESS INTELLIGENCE
               </h1>
               <div style={{width:"100%",paddingLeft:15,display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}><div style={{width:"100%",height:"0.4px",backgroundColor:"white"}}></div>
               </div>
                 </div>
                 <div className='craxa'>
                 <div style={{display:"flex",flexDirection:"row",alignItems:"center"}}>
                 <h1
                 className="roboto-black dwqeq" style={{marginTop:10}}>
                 IS
               </h1>
               <div style={{display:"flex",flexDirection:"column"}}>
                <h1 className='roboto-bold altyaz' >
                  Istanbul
                </h1>
                <span className='roboto-regular dwqeqalşt' >Kozyatağı Mah. Değirmen Sok. Cemal Bey İş Merkezi No:34 Kadıköy İstanbul.</span>
               </div>
               </div>
               <div style={{display:"flex",flexDirection:"column"}}>
                   <div style={{display:"flex",flexDirection:"row",alignItems:"center"}}>
                   <span className='roboto-thin' style={{fontSize:18,marginRight:8}}>|</span>

                   <HiPhone size={18} color='white'/>
                   <span className='roboto-thin' style={{fontSize:18,marginLeft:5}}><a href="tel:+905422940432" style={{textDecoration: 'none', color: 'inherit'}}>(90) 542 294 04 32</a>
                    </span>

                   </div>
                   <div style={{display:"flex",flexDirection:"row",alignItems:"center"}}>
                   <span className='roboto-thin' style={{fontSize:18,marginRight:8}}>|</span>

                   <IoMailSharp size={18} color='white'/>
                   <span className='roboto-thin' style={{fontSize:18,marginLeft:5}}>      <a href="mailto:sales@progressint.com.tr"  style={{textDecoration: 'none', color: 'inherit'}}>sales@progressint.com.tr</a>

                    </span>

                   </div>
                   <div style={{display:"flex",flexDirection:"row",alignItems:"center"}}>
              <div className='xsdss'>
              <a href="https://tr.linkedin.com/company/buzztech-yazilim" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
                <FaLinkedinIn size={20} color='white' />
                </a>

              </div>
              <div className='xsdss'>
                <ImInstagram size={20} color='white' />
              </div>
              <div className='xsdss'>
                <FaFacebookSquare size={20} color='white' />
              </div>
              <div className='xsdss'>
                <FaSquareXTwitter size={20} color='white' />
              </div>
                   </div>

                 </div>
                 </div>
           
                 </div>
                </div>
           
            </footer>
            <ScrollTop />
        </>
    )
}

export default FooterFour;
