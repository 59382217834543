import React, { useContext, useState } from 'react';
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderOne';
import FooterFour from 'common/footer/FooterFour';
import LanguageContext from '../LanguageContext';
import logo from '../assets/images/bg/officebg.webp'
import Building from 'elements/worldmaps/buildings';
const TabContent = ({ title, children }) => (
  <div className="tab-contentbuz">
    {children}
  </div>
);

function HeaderUs() {
  const { translations } = useContext(LanguageContext);
  const [activeTab, setActiveTab] = useState('about');

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <>
      <SEO title="Hakkımızda" />
      <main >
        <Building></Building>

   
      </main>
    </>
  );
}

export default HeaderUs;
