import React, { useEffect,useContext } from 'react';
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderOne';
import HeaderTopBar from '../common/header/HeaderTopBar';
import FooterThree from '../common/footer/FooterThree';
import BrandThree from '../elements/brand/BrandThree';
import AboutFour from '../elements/about/AboutFour';
import ServiceOne from "../elements/service/ServiceOne";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import CounterUpFour from "../elements/counterup/CounterUpFour";
import TeamFour from "../elements/team/TeamFour";
import TimelineTwo from "../elements/timeline/TimelineTwo";
import Separator from "../elements/separator/Separator";
import AboutThree from '../elements/about/AboutThree';
import ServiceFive from "../elements/service/ServiceFive";
import Elements from 'elements/gallery/Gallery';
import { FiArrowRight } from "react-icons/fi";
import FooterFour from 'common/footer/FooterFour';
import LanguageContext from '../LanguageContext';
import ContactForm from 'elements/worldmaps/customform';
import ContactInfoCustom from 'elements/worldmaps/contactinfo';
import AboutUsHome from './AboutUsHome';
import Dxx from './newHOMEHOME' 
import { FaLinkedinIn } from "react-icons/fa";
import { Link } from 'react-router-dom'; // Link'i ekleyin
import aboustusimage from '../assets/images/bg/aboutusbg.png'

import { HiArrowLongLeft  } from "react-icons/hi2";

function NewAboutUs  ()  {
  const { translations } = useContext(LanguageContext);

    return (
        <>
            <SEO title="Hakkımızda" />
            <main className=" modal-contentxss" >
               <div>
            <HeaderOne  btnStyle="btn-small btn-icon" HeaderSTyle="rn-header  header-transparent header-default " Loco="main"/>
            </div>
            <div style={{width:"100vw",height:"100vh",position:"relative"}}>
            <img src={aboustusimage} alt="Blog Image" className="bigimadsdcxl " />
            <div className='centered-divdsdsds'>
            <p className="roboto-bold widft">{translations.aboutuscontent}</p>  
            </div>
            
            <div className='centered-divdsdsds2222'>
            <div style={{marginTop:-18}}>
            <HiArrowLongLeft  size={30} color='white' />
       </div>
            <p className="roboto-thin widft225">{translations.aboutuscroll}</p>  

            </div>
            


            </div>

            <Dxx translations={translations}/>
  
                <div style={{position:"relative",zIndex:5}}>
                <ContactInfoCustom translations={translations}/>
                </div>
        
            </main>
        </>
    )
}

export default NewAboutUs;
