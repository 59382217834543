import React, { useState, useEffect,useContext } from 'react';
import LanguageContext from "../../LanguageContext";
import { RiCompassDiscoverLine } from "react-icons/ri";
import backgroundImage from '../../assets/images/bg/blueback.png'; // Yolu doğru belirttiğinizden emin olun
import CustomCarousel from './emblavertical';
import CustomCarouselHorizontal from './emblahorizantal';
import { MdArrowRight } from "react-icons/md";
import TabPanel from './tabpanel';
import TabPanel3 from './tabpanel3';

const BackgroundVideo2 = ({translations}) => {
 
   // Resim verilerini bir array olarak tanımlayın
   const slides = [
    { id: 1, url: 'https://i.ibb.co/3s5zpSN/site1.png', alt: 'Description of Image 1' },
    { id: 2, url: 'https://i.ibb.co/p3WH747/site2.png', alt: 'Description of Image 2' },
    { id: 3, url: 'https://i.ibb.co/vXZSjft/site3.png', alt: 'Description of Image 3' },
    { id: 4, url: 'https://i.ibb.co/Q80gTxP/site4.png', alt: 'Description of Image 4' },
    { id: 5, url: 'https://i.ibb.co/J7QkmHY/site5.png', alt: 'Description of Image 1' },
    { id: 6, url: 'https://i.ibb.co/qNJcJGp/site6.png', alt: 'Description of Image 1' },
  ];

  const slides2 = [
    { id: 1, url: 'https://i.ibb.co/ZzW4X2M/picture1.png', alt: 'Description of Image 1' },
    { id: 2, url: 'https://i.ibb.co/CWfP1bQ/picture2.png', alt: 'Description of Image 2' },
    { id: 3, url: 'https://i.ibb.co/VxV1pt0/picture3.png', alt: 'Description of Image 3' },
    { id: 4, url: 'https://i.ibb.co/qnvQTzC/picture4.png', alt: 'Description of Image 1' },
    { id: 5, url: 'https://i.ibb.co/t3dnNrS/picture5.png', alt: 'Description of Image 1' },
  ];


  
  return (
    <div className='page-wrapper'>
<div className='secondpartmainarkamain'>
<div className='secondpartmainarka'>
<h1 className='roboto-bold' style={{fontSize:20,letterSpacing:0.4}}>{translations.homecontent1}</h1>
<h1 className='roboto-black mainsecondparth1'>{translations.homecontent2} </h1>
<h1 className='roboto-thin mainsecondpartspaninner'>{translations.homecontent3}<span className='roboto-bold'>{translations.homecontent4}</span></h1>
</div>
<div>
<TabPanel3 translations={translations}/>
</div>
</div>
    </div>
  );
};

export default BackgroundVideo2;