import React, { useState } from 'react';
import {Link} from "react-router-dom";

function Littlenews({ id,title, image,date}) {

  return (
    <div className="container4060">
    <div className="column40">
    <Link to={process.env.PUBLIC_URL + `/blog-detay/${id}`} >
    <img src={`${process.env.PUBLIC_URL}/${image}`} alt="Blog Image" className="littleimage " />
    </Link>

    </div>
    <div className="column60">
    <span className='roboto-black xdseew' style={{ color:"#04E4FF"}}>{date}</span>
    <Link to={process.env.PUBLIC_URL + `/blog-detay/${id}`} >

    <span className='title400 roboto-bold'>{title}</span>
    </Link>

    </div>
  </div>
  );
}

export default Littlenews;
