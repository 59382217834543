import React, { useState } from 'react';

function Accordion({ title, children,isCor,isBorder }) {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div style={{display:"grid",justifyContent:"center"}}>
    <div 
      className="accordionbuz" 
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className={`titlebuz ${isHovered ? 'smallbuz' : ''}`}>
        {title}
      </div>
      {isHovered && (
        <div className={`contentbuz ${isHovered ? 'daedasx' : 'daedas'}`}>
          {children}
        </div>
      )}
    </div>
    {isCor && (
        <div style={{display:"flex",flexDirection:"row",justifyContent:"center"}}>
          <div style={{width:"20%"}}><h1 className='roboto-bold colorheaderh1'>Branding & Digital Strategies</h1></div>
          <div style={{width:"20%"}}><h1 className='roboto-bold colorheaderh1'>Web Design & Development</h1></div>
          <div style={{width:"20%"}}><h1 className='roboto-bold colorheaderh1'>Mobile App Development</h1></div>
          <div style={{width:"20%"}}><h1 className='roboto-bold colorheaderh1'>Power bi Services</h1></div>


        </div>
      )}
    <div style={{width:"100%",justifyContent:"center",display:"grid",textAlign:"center"}}>

      {isBorder && (
        <div style={{width:"80px",height:"1px",backgroundColor:"cyan",marginTop:"12px"}}></div>

      )}
</div>
</div>

  );
}

export default Accordion;
