import { useState, useRef, useContext } from "react";
import { FiMenu } from "react-icons/fi";
import { FiArrowRight } from "react-icons/fi";
import Logo from "../../elements/logo/Logo";
import Nav from "./Nav";
import MobileMenu from "./MobileMenu";
import Darkmode from "./Darkmode";
import useStickyHeader from "./useStickyHeader";
import { useNavigate } from "react-router-dom";
import LanguageContext from "../../LanguageContext.js";
import { IoIosArrowDown } from "react-icons/io";
import { Link } from "react-router-dom";
import MenuButton from "./Menubutton";
const HeaderOne = ({ btnStyle, HeaderSTyle, Loco, isBorder, obien = true }) => {
  const { translations } = useContext(LanguageContext);
  const navigate = useNavigate();

  const [ofcanvasShow, setOffcanvasShow] = useState(false);
  const onCanvasHandler = () => {
    setOffcanvasShow((prev) => !prev);
  };
  const ref = useRef();
  let [check, setCheck] = useState(true);
  const sticky = useStickyHeader(50);
  const headerClasses = `header-default ${sticky && check ? "sticky" : ""} `;
  const headerStyle = {
    backgroundColor: obien ? 'rgba(255,255,255,1)' : 'rgba(0,0,0,0)',
    important: true
  };
  const { clientHeight } = ref;
  /* <Darkmode />*/

  const checkChange = (value) => {
    setCheck(value);
  };
  const scrollDown = () => {
    if (Loco === "main") {
      window.scrollTo({ top: 1400, left: 0, behavior: "smooth" });
    } else {
      navigate("/getoffer"); // '/getoffer' sayfasına yönlendir
    }
  };
  return (
    <>
      <header
        style={{
          zIndex: "994",
          borderBottom: isBorder
            ? "1px solid rgba(255, 255, 255, 0.4)"
            : "none", // Koşullu stil
        }}
        ref={ref}
        className={`rn-header header-default ${HeaderSTyle} ${headerClasses}`}
      >
        <div>
          <div className="row align-items-center row--0">
            <div className="col-lg-4 col-md-6 col-9 col-sm-9">
                        
            <Link to="/" >     
              <h1
                className="beyond elelstyle"
              >
              PROGRESS INTELLIGENCE
              </h1>
              </Link>
            </div>
            <div className="col-lg-8 col-md-6 col-3 col-sm-3">
              <div style={{ justifyContent: "flex-end", display: "flex" }}>
                <nav className="mainmenu-nav d-none d-lg-block">
                  <Nav />
                </nav>

                <div className="mobile-menu-bar ml--5 d-block d-lg-none">
                  <div style={{ marginRight: "10px" }} className="hamberger">
                  <MenuButton />

                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-2 col-md-6 col-1"
              style={{ padding: 0, textAlign: "left" }}
            ></div>
          </div>
        </div>
      </header>
    </>
  );
};
export default HeaderOne;
