import React, { useRef, useEffect } from 'react';

const CustomCarousel = ({ slides, speed = 5, direction = true }) => {
  const innerRef = useRef(null);

  useEffect(() => {
    const interval = setInterval(() => {
      const current = innerRef.current;
      if (current) {
        const { scrollHeight, clientHeight } = current;
        const currentScroll = current.scrollTop;

        if (direction) {
          // Aşağı kaydırma
          if (currentScroll >= scrollHeight - clientHeight) {
            current.scrollTop = 0; // Başa dön
          } else {
            current.scrollTop += 1;
          }
        } else {
          // Yukarı kaydırma
          if (currentScroll <= 0) {
            current.scrollTop = scrollHeight - clientHeight; // En alttan başlat
          } else {
            current.scrollTop -= 0.1;
          }
        }
      }
    }, speed);

    return () => clearInterval(interval);
  }, [direction, speed]);

  const renderSlides = (reversed = false) => {
    const orderedSlides = reversed ? [...slides].reverse() : slides;
    return (
      <>
        {orderedSlides.map((img, index) => (
          <div style={{ padding: 5 }} key={index + slides.length}>
            <img className='slidesimgx' src={img.url} alt={`Image ${index + 1}`} />
          </div>
        ))}
        {orderedSlides.map((img, index) => (
          <div style={{ padding: 5 }} key={index}>
            <img className='slidesimgx' src={img.url} alt={`Image ${index + 1}`} />
          </div>
        ))}
      </>
    );
  };

  return (
    <div className="scroller innerxx" style={{ height: '150%', position: 'relative', width: '500px', overflow: 'hidden',marginTop:"-5%"}}>
      <div  ref={innerRef} style={{ height: '100%', overflowY: 'hidden' }}>
        {renderSlides(direction === false)}
      </div>
    </div>
  );
};

export default CustomCarousel;
