import React from 'react';
import {slugify} from "../../../utils";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import MDBox from 'components/MDBox';


const BlogList = ({ data, StyleVar }) => {
    function limitContentByWords(content, limit) {
        const words = content.split(' ');
        const limitedWords = words.slice(0, limit);
        return limitedWords.join(' ');
      }
      
      // Kullanım örneği
      const originalContent = "Bu içerik örnek bir metin içerir ve 150 kelimeden fazla olabilir.";
      const limitedContent = limitContentByWords(data.body, 15);
    
    return (
        <div className={`rnn-card ${StyleVar}`}>
            <div className="inner">
                <div className="thumbnail ccxx">

                    <Link to={process.env.PUBLIC_URL + `/blog-detay/${data.id}`} className="image">
                    <img src={`${process.env.PUBLIC_URL}/${data.image}`} alt="Blog Image" className="innerbreaker " />
                    </Link>
                    </div>

                <div className="content">
                    <ul className="rn-meta-list" style={{padding:0,margin:0}}>
                   
                        <li className='roboto-black xdseew' style={{ color:"#04E4FF"}}>{data.date}</li>
                    </ul>
                    <h4 className="title roboto-bold">
                        <Link to={process.env.PUBLIC_URL + `/blog-detay/${data.id}`}>
                            {data.title}
                        </Link>
                    </h4>
              
                </div>
            </div>
        </div>
    )
}
BlogList.propTypes = {
    data: PropTypes.object
};
export default BlogList;
