import React from 'react';
import { RxPerson } from "react-icons/rx";
import { SlHome } from "react-icons/sl";
import { AiOutlineMail } from "react-icons/ai";
import { BsTelephone } from "react-icons/bs";
import { FiMessageSquare } from "react-icons/fi";

function ContactForm({translations}) {
  return (
    <div className="contact-formbuz" >
      <div className="input-groupbuz">
        <input style={{color:"gray"}} type="text" placeholder={translations.contantleformname} required />
        <div className='absolteicker'> 
        <RxPerson size={22} color='#1B398F'/>
</div>
      </div>
      <div className="input-groupbuz">
        <input style={{color:"gray"}} type="text" placeholder={translations.contantleformcompname} required />
        <div className='absolteicker'> 
        <SlHome size={22} color='#1B398F'/>
</div>
      </div>
      <div className="input-groupbuz">
        <input style={{color:"gray"}} type="email" placeholder={translations.contantleformcompmail} required />
        <div className='absolteicker'> 
        <AiOutlineMail size={22} color='#1B398F'/>
</div>
      </div>
      <div className="input-groupbuz">
        <input style={{color:"gray"}} type="tel" placeholder={translations.contantleformcompphone}  required />
        <div style={{position:"absolute",top:"-2px"}}> 
        <BsTelephone size={20} color='#1B398F'/>
</div>
      </div>
      <div className="input-groupbuz">
        <textarea style={{color:"gray"}} placeholder={translations.contantleformcomptext} required></textarea>
        <div style={{position:"absolute",top:8}}> 
        <FiMessageSquare size={22} color='#1B398F'/>
</div>
      </div>
      <button className='roboto-bold formbutton' type="submit">{translations.contantleformcomsend}</button>
    </div>
  );
}

export default ContactForm;
