import React, { useState, useEffect } from 'react';
import { FaSistrix } from "react-icons/fa";

const SearchBar = ({translations}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [isDisabled, setIsDisabled] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 1200) {
                setIsDisabled(true);
                setIsOpen(false); // Kapatıldığında input'u temizler
            } else {
                setIsDisabled(false);
            }
        };

        // İlk renderda kontrol et
        handleResize();

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleIconClick = () => {
        if (isDisabled) return;

        if (isOpen) {
            setSearchText("");
        }
        setIsOpen(!isOpen);
    };

    const handleInputChange = (event) => {
        setSearchText(event.target.value);
    };

    return (
        <div className="search-container roboto-thin">
            <div className={`search-box ${isOpen ? 'open' : ''}`}>
                <input 
                    type="text" 
                    placeholder={translations.blogsearch} 
                    value={searchText}
                    onChange={handleInputChange}
                />
            </div>
            <FaSistrix className={`search-icon ${isDisabled ? 'disabled' : ''}`} onClick={handleIconClick} />
        </div>
    );
};

export default SearchBar;
