import { useState, useRef, useContext } from "react";
import Accordion from './Acco';
import { FaLinkedinIn } from "react-icons/fa";
import { Link } from 'react-router-dom'; // Link'i ekleyin
import LanguageContext from "LanguageContext";
import { ImInstagram } from "react-icons/im";
import { FaFacebookSquare } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";
const Modal = ({ isOpen, toggleModal }) => {
  const { translations } = useContext(LanguageContext);

  return (
    <div>
      {isOpen && (
        <div className="modal-overlay" onClick={toggleModal}>
          <div className="modal-content" onClick={e => e.stopPropagation()}>
            <div className='modaldsdasfsa'>
              
              <Link to="/" onClick={toggleModal} style={{ textDecoration: 'none', color: 'inherit' }}>
                <Accordion title={translations.navhome} isCor={false} isBorder={true}>
                  {translations.navhomesub}
                </Accordion>
              </Link>
              
              <Link to="/aboutus" onClick={toggleModal} style={{ textDecoration: 'none', color: 'inherit' }}>
                <Accordion title={translations.navabout} isCor={false} isBorder={true}>
                {translations.navaboutsub}
                </Accordion>
              </Link>
              <Link to="/news" onClick={toggleModal} style={{ textDecoration: 'none', color: 'inherit' }}>
                <Accordion title={translations.navnews} isCor={false} isBorder={true}>
                {translations.navnewssub}
                </Accordion>
              </Link>
              
              
      
              
              <Link to="/contact" onClick={toggleModal} style={{ textDecoration: 'none', color: 'inherit' }}>
                <Accordion title={translations.navcontnav} isCor={false} isBorder={false}>
                {translations.navcontnavsub}
                </Accordion>
              </Link>

            </div>
            
            <div style={{opacity:0.2}}>
              <h1 className="responsive-heading beyond">PROGRESS</h1>
            </div>
            
            <div className='followusstyle'>
              <div style={{width:"80px",height:"1px",backgroundColor:"cyan",marginTop:"12px"}}></div>
              <span className='roboto-regular followtext'>{translations.contantlefollow}</span>
              <div className='borderin'>
              <a href="https://tr.linkedin.com/company/buzztech-yazilim" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
                <FaLinkedinIn size={20} color='white' />
                </a>

              </div>
              <div className='borderin'>
                <ImInstagram size={20} color='white' />
              </div>
              <div className='borderin'>
                <FaFacebookSquare size={20} color='white' />
              </div>
              <div className='borderin'>
                <FaSquareXTwitter size={20} color='white' />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Modal;
